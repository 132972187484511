import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
import projImg4 from "../assets/img/project-img4.png";
import projImg5 from "../assets/img/project-img5.png";
import projImg6 from "../assets/img/project-img6.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import ReactPlayer from "react-player";

export const Projects = () => {

  const projects = [
    {
      title: "React, Angular, Vue",
      description: "for building interactive user interfaces",
      imgUrl: projImg1,
    },
    {
      title: "Python",
      description: "Versatile language for diverse applications.",
      imgUrl: projImg2,
    },
    {
      title: "JavaScript",
      description: "Dynamic language for web development.",
      imgUrl: projImg3,
    },
    {
      title: "Django",
      description: "High-level Python framework for web",
      imgUrl: projImg4,
    },
    {
      title: "Laravel",
      description: "Elegant PHP framework for web applications.",
      imgUrl: projImg5,
    },
    {
      title: "PHP",
      description: "Server-side scripting language for web.",
      imgUrl: projImg6,
    },
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Projects</h2>
                <p>A couple of projects I completed; I ask for understanding regarding the fact that, as a recent graduate, my portfolio may be a bit varied in terms of topics and small in quantity.</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">programming</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Video Sample</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">More about me</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <p>A short video that shows a video game slightly modifying the RPGMaker MV system to resemble the video game Undertale; the designs shown are from the mentioned video game.</p>
                      <p>The game is made in Spanish (my native language)</p>
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <ReactPlayer url="https://youtu.be/KQYPR0FITDc" controls />
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <p>I started programming simple things around middle school, and in high school, I continued programming, including simple conditionals, as I had a certain liking for programming. I have some color palettes that I commonly use for web development, and I have also ventured into game development as a simple hobby.</p>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
