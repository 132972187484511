import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";


const firebaseConfig = {
    apiKey: "AIzaSyARlr-bjZz-pq1J3oYX3jfqXmzfrCm2_jA",
    authDomain: "felixyair-8ff8d.firebaseapp.com",
    projectId: "felixyair-8ff8d",
    storageBucket: "felixyair-8ff8d.appspot.com",
    messagingSenderId: "110151698025",
    appId: "1:110151698025:web:a03a8f63930aa3dd7b04f8",
    measurementId: "G-Q2S69Y5FVV"
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
